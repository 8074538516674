import React, { useState } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { VictoryChart, VictoryArea, VictoryTheme, VictoryAxis } from 'victory'

type Props = {
  available: number
  title: string
  timeframe: string
  slot: string,
  day: string,
  chart: any
}

const disabledStyle = {
  opacity: 0.5,
  cursor: 'not-allowed'
}

const Ticket = ({available, title, day, timeframe, slot, chart}: Props) => {
  const isPast = moment(day + ' ' + timeframe.split(/-\s*/)[1]).subtract(1, 'hour').isBefore(moment())
      , [raised, setRaised] = useState<boolean>(false)
  return (
    <Card
      sx={{
        width: 220,
        cursor: 'pointer',
        ...(isPast || !available ? disabledStyle : {})}
      }
      raised={raised}
      onMouseOver={() => setRaised(true)} 
      onMouseOut={() => setRaised(false)}

      onClick={() =>
        isPast || (window.location.href = 'https://shop.das-stadtwerk-regensburg.de/de/orders/form/eticket_applications/timeslot_tariff_id/' + slot)
      }
    >
      <CardContent sx={{position: 'relative'}}>
        <Typography variant="h3" component="div" style={{float: 'right', color: 'red', fontSize: 30}}>
          {available}
        </Typography>
        <Typography gutterBottom variant="h5" component="div" style={{fontSize: 20, marginTop: 4}}>
          {title.replace(/(Einzelkarte)/g, '').trim()}
        </Typography>
        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 30, padding: 0}}>
          <VictoryChart
            theme={VictoryTheme.material}
            animate={{duration: 1000}}
            height={30}
            width={220}
            padding={0}
            maxDomain={{y: 60, ...(isPast ? {} : {x: 212})}}
          >
            <VictoryAxis style={{ 
              axis: {stroke: 'transparent'},
              ticks: {stroke: 'transparent'},
              tickLabels: {fill: 'transparent'},
              grid: {fill: 'transparent'},
            }} />
            <VictoryArea
              data={chart}
              interpolation='basis'
              style={{data: {
                stroke: '#c43a31a0',
                strokeWidth: 3,
                fill: 'none'
              }}}
            />
          </VictoryChart>
        </div>
      </CardContent>
    </Card>
  )
    }
export default Ticket