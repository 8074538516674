import React from 'react'
import './App.css'
import Ticket from './components/Ticket'
import Typography from '@mui/material/Typography'
import useFetch from 'react-fetch-hook'
import moment from 'moment'
import 'moment/min/locales'
moment.locale('de')

// const data = [{"date":"2021-10-13","tickets":[{"available":30,"name":"Einzelkarte Sauna Früh","timeframe":"09:00-15:00","slot":98353,"chart":[{"x":0,"y":50}]},{"available":0,"name":"Einzelkarte Sauna Spät","timeframe":"15:30-22:00","slot":96823,"chart":[{"x":0,"y":49}]}]},{"date":"2021-10-14","tickets":[{"available":41,"name":"Einzelkarte Sauna Früh","timeframe":"09:00-15:00","slot":98356,"chart":[{"x":0,"y":50}]},{"available":14,"name":"Einzelkarte Sauna Spät","timeframe":"15:30-22:00","slot":96826,"chart":[{"x":0,"y":50}]}]},{"date":"2021-10-15","tickets":[{"available":46,"name":"Einzelkarte Sauna Früh","timeframe":"09:00-15:00","slot":98359,"chart":[{"x":0,"y":50}]},{"available":24,"name":"Einzelkarte Sauna Spät","timeframe":"15:30-22:00","slot":96829,"chart":[{"x":0,"y":50}]}]},{"date":"2021-10-16","tickets":[{"available":40,"name":"Einzelkarte Sauna Früh","timeframe":"09:00-15:00","slot":98362,"chart":[{"x":0,"y":50}]},{"available":6,"name":"Einzelkarte Sauna Spät","timeframe":"15:30-22:00","slot":96832,"chart":[{"x":0,"y":50}]}]},{"date":"2021-10-17","tickets":[{"available":50,"name":"Einzelkarte Sauna Früh","timeframe":"09:00-15:00","slot":98365,"chart":[{"x":0,"y":50}]},{"available":50,"name":"Einzelkarte Sauna Spät","timeframe":"15:30-22:00","slot":96835,"chart":[{"x":0,"y":50}]}]}]

function App() {
  const { data } = useFetch<any>('/data')

  return (
    <div className="App">
      {data && data.filter((d: any) => d.date !== '2021-10-12').map((day: any) =>
        <>
          <Typography gutterBottom variant="h5" component="div" className="date">
            {(
              moment(day.date).isBefore(moment().add(1, 'day').startOf('day')) ? 'Heute, ' + moment(day.date).format('Do MMMM') : 
              moment(day.date).isBefore(moment().add(2, 'day').startOf('day')) ? 'Morgen, ' + moment(day.date).format('Do MMMM') : 
              moment(day.date).format('dddd, Do MMMM')
            )}
          </Typography>
          <div style={{display: 'flex', marginBottom: 20}}>
            {day.tickets.map((ticket: any) =>
              <>
                <Ticket
                  day={day.date}
                  available={ticket.available}
                  title={ticket.name}
                  timeframe={ticket.timeframe.replace('-', ' - ')}
                  slot={ticket.slot}
                  chart={ticket.chart}
                />
                <div style={{width: 20}}/>
              </>
            )}
          </div>
        </>
      )}

    </div>
  );
}

export default App;
